import tracker from '@capturi/analytics'
import { Role } from '@capturi/core'
import CancelActionModal from '@capturi/dashboard/src/views/CancelActionModal'
import { useFeatureFlags } from '@capturi/feature-flags'
import { getErrorMessage } from '@capturi/request'
import { useToast } from '@capturi/ui-components'
import { OnChangeValue, Select, SelectOption } from '@capturi/ui-select'
import { useConfirm } from '@capturi/use-confirm'
import { useModal } from '@capturi/use-modal'
import { Td } from '@chakra-ui/react'
import { select, t } from '@lingui/macro'
import { UiRole } from 'pages/Organization/hooks/useUserActions'
import React, { useState } from 'react'

export const localizeRole = (role: UiRole | string): string =>
  select(role, {
    user: 'User',
    teamlead: 'Team lead',
    administrator: 'Admin',
    owner: 'Owner',
    other: typeof role === 'string' ? role : 'unknown',
  })

const roles = [
  { value: 'owner', label: localizeRole('owner') },
  {
    value: Role.administrator,
    label: localizeRole(Role.administrator),
  },
  { value: Role.teamlead, label: localizeRole(Role.teamlead) },
  { value: Role.user, label: localizeRole(Role.user) },
]

type Props = {
  role: string
  currentUserUid: string
  userUid: string
  width?: string
  changeRole: (userUid: string, newRole: UiRole) => Promise<void>
}
const RoleCell: React.FC<Props> = ({
  role,
  currentUserUid,
  userUid,
  width,
  changeRole,
}) => {
  const toast = useToast()
  const [showCancelActionModal] = useModal(CancelActionModal)
  const { disableUserConfig } = useFeatureFlags()

  const [inProgress, setInProgress] = useState<boolean>(false)
  const confirm = useConfirm()

  const handleRoleChange = async (
    e: OnChangeValue<SelectOption, false>,
  ): Promise<void> => {
    if (e === null) return
    const newRole = e.value as UiRole
    try {
      tracker.event('organizationPage_userRoleChanged', {
        newRole,
        oldRole: role,
      })
      await changeRole(userUid, newRole)
      if (role === 'owner' && currentUserUid === userUid)
        window.location.href = '/analytics/trackers'
    } catch (error) {
      const errorMsg = getErrorMessage(error)
      toast({
        title: t`Could not update user role`,
        description: errorMsg,
        status: 'error',
      })
    }
  }

  const onRoleChange = async (
    e: OnChangeValue<SelectOption, false>,
  ): Promise<void> => {
    if (e === null) return
    const newRole = e.value as UiRole
    tracker.event('organizationPage_userRoleChanged', {
      newRole,
      oldRole: role,
    })
    setInProgress(true)
    if (role === 'owner' && currentUserUid === userUid) {
      await confirm({
        title: t`Downgrading your role`,
        description: t`Warning: You will no longer be able to access the organization page and make changes to users.`,
        cancelText: t`Cancel`,
        confirmText: t`Change role`,
      })
      showCancelActionModal({
        action: () => handleRoleChange(e),
        label: t`Downgrading your role`,
        description: t`After this you will no longer be able to access the organization page`,
      })
    } else {
      handleRoleChange(e)
    }
    setInProgress(false)
    return
  }

  return (
    <Td width={width}>
      <Select
        isDisabled={inProgress || disableUserConfig}
        value={{ value: role, label: localizeRole(role) }}
        options={roles}
        placeholder={t`Select role ...`}
        onChange={onRoleChange}
        menuPortalTarget={document.body}
        menuPosition="fixed"
      />
    </Td>
  )
}

export default RoleCell
