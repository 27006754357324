import tracker from '@capturi/analytics'
import { Role } from '@capturi/core'
import { OnChangeValue, Select, SelectOption } from '@capturi/ui-select'
import { Td, useToast } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React, { useState } from 'react'

import { useFeatureFlags } from '@capturi/feature-flags'
import { Team } from '../../types'

const formatOption = (team: Team): SelectOption => ({
  value: team.uid,
  label: team.name,
})
type Props = {
  teams: Team[]
  teamLeadTeams: Team[]
  role: string
  width?: string
  userUid: string

  changeTeamLeadTeams: (userUid: string, teams: Team[]) => Promise<void>
}
const TeamLeadCell: React.FC<Props> = ({
  role,
  teamLeadTeams = [],
  teams,
  width,
  changeTeamLeadTeams,
  userUid,
}) => {
  const isTeamLead = role === Role.teamlead
  const { disableUserConfig } = useFeatureFlags()
  const toast = useToast()
  const [inProgress, setInProgress] = useState<boolean>(false)

  const handleChange = async (
    e: OnChangeValue<SelectOption, true>,
  ): Promise<void> => {
    try {
      setInProgress(true)
      const option = e || []
      const teams = option.map((o) => ({ uid: o.value, name: o.label }))
      tracker.event('organizationPage_userTeamLeadChanged', {
        teams: teams.length,
      })
      await changeTeamLeadTeams(userUid, teams)
      toast({
        title: t`Whoop, user is now team lead for ${e.length} teams`,
        status: 'success',
      })
    } catch (error) {
      let errMsg: null | string = null
      if (error instanceof Error) {
        errMsg = error.message
      }

      toast({
        title: t`Could not update the user's team`,
        description: errMsg,
        status: 'error',
      })
    } finally {
      setInProgress(false)
    }
  }

  if (!isTeamLead) return <Td />
  return (
    <Td maxWidth={width}>
      <Select
        isDisabled={inProgress || disableUserConfig}
        isMulti={true}
        value={teamLeadTeams?.map(formatOption)}
        options={teams.map(formatOption)}
        placeholder={t`Select teams ...`}
        onChange={handleChange}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        hideSelectedOptions={false}
        backspaceRemovesValue={false}
        isClearable={false}
      />
    </Td>
  )
}

export default TeamLeadCell
